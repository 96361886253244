.upButton{
    position: fixed;
    width: 100px;
    height: 100px;
    /* object-fit: contain; */
    border: none;
    /* background-color: transparent; */
    right: 4%;
    bottom: 2%;
    cursor: pointer;
    z-index: 10000;
}


.upButton:hover path {
    fill: #BAF32E;
    stroke: #BAF32E !important;
}

@media (max-width: 768px) {
    .upButton {
        display: none;
        position: fixed;
        width: 60px;
        height: 60px;
        border: none;
        right: 5%;
        bottom: 5%;
        cursor: pointer;
    }

    .upButton:hover path {
        fill: #BAF32E;
        stroke: #BAF32E !important;
    }
}