.video-container {
  position: relative;
}

.video-button {
  border: 2px solid #baf32e;
  background-color: transparent;
  border-radius: 30px;
  color: #ffffff;
  padding: 10px 20px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.2s linear;
}

.video-button:hover {
  transform: scale(1.07);
  
}

.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); 
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0; 
  transition: background-color 0.3s ease, opacity 0.3s ease; 
  z-index: 2;
  pointer-events: none; 
}

.video-overlay.visible {
  background-color: rgba(0, 0, 0, 0.85); 
  opacity: 1;  
  pointer-events: auto; 
}

.video-overlay.closing {
  background-color: rgba(0, 0, 0, 0); 
  opacity: 0;
}

.button-icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.video-content {
  position: relative;
  width: 90%;
  max-width: 1200px;
  max-height: 90%;
  border-radius: 40px;
  overflow: visible;
  transform: scale(0);
  opacity: 0; 
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; 
  background: #000;
}

.video-content.scale-up {
  transform: scale(1);
  opacity: 1; 
}

.video-content.scale-down {
  transform: scale(0);
  opacity: 0; 
}

.video-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 40px;
  overflow: hidden;
}

.close-button {
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 11px;
  right: 11px;
  transform: translate(50%, -50%);
  z-index: 3;
  pointer-events: auto;
}

.close-button-image {
  width: 60px;
  height: 60px;
}

.video-element {
  width: 100%;
  height: 100%;
  display: block;
  background: #000;
  pointer-events: auto;
  border-radius: 40px;
}