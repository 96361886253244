.cyber{
    /* background-color: black; */
    padding-top: 50px;
}

.cyber__container{
    max-width: 1280px;
    width: 100%;
    margin:  0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    /* padding-bottom: 50px; */
}


.cyber__overlay-container{
    position: relative;
    width: 608px;
    /*display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;*/

}


.cyber__video{
    width: 100%;
}


.cyber__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0); /* Example semi-transparent black overlay */
    z-index: 10; /* Ensures overlay is above the video */
}


.cyber__about{
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
    gap: 30px;
    /*max-width: 600px;*/
    width: 580px;

}



.cyber__title{
    margin: 0;
    font-size: 20px;
}


.cyber__subtitle{
    font-family: DERZKY;
    font-size: clamp(18px, 2vw, 22px);
    line-height: 1.25;
    margin-left: 0px;
    padding-left: 0px;
    z-index: 1;
    text-align: left;
}

.cyber__button{
    border: 2px solid #baf32e;
    background-color: transparent;
    border-radius: 30px;
    color: #ffffff;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}

.cyber__button{
    transition: transform 0.2s linear;
}

.cyber__button:hover{
    transform: scale(1.07);
}

.services__circle-decor {
    display: block;
    margin-bottom: 50px;
    width: 218px;
    animation: spin 20s infinite linear;
    z-index: 1000;
}

@media screen and (max-width: 1150px){
    .cyber__video{
        width: 300px;
    }
    .cyber__overlay-container{
        width: 350px;}
    
}

@media screen and (max-width: 860px){
    
    .cyber__container{
        
        justify-content: center;
    }

    .cyber__about{
        justify-content: center;
        align-items: center;
    }

    .cyber__subtitle{
        text-align: center;
        font-size: 16px;
        width: 300px;
    }

    .cyber__overlay-container{
        width: 350px;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .cyber__title{
        font-size: 20px;
    }

    .services__circle-decor{
        display: block;
        margin: 20px auto 50px;
    }
}