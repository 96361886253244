
.text_main{
    /* margin-top: 250px; */
    max-width: 1280px;
    width: 100%;
    margin: 0 auto 0;
    display: flex;
    justify-content: center; /* Горизонтальное выравнивание */
    align-items: center; 
    
    /* background-color: rgb(0, 0, 0); */
}

.text__subtitle{
    color: #ffffff;
    font-size: 25px;
    max-width: 850px;
    margin-top: 80px;
    /* font-family: "DERZKY"; */
    text-transform: uppercase;

}




@media screen and (max-width: 767px) {
    .main__grid{
        grid-template-columns: repeat(8, minmax(20px, 37px));
        grid-template-rows: repeat(9, 42px);
    }
    .main__title-img{
        width: 185px;
        height: 36px;
    }

    .main__subtitle{
        font-size: 15px;
        max-width: 344px;
    }

    .main__grid-item1{
        grid-column: 1/9;
        grid-row: 1/5;
        border: none;
    }

    .main__grid-item2{
        grid-column: 5/9;
        grid-row: 5/7;
    }

    .main__grid-item3{
        display: none;
    }

    .main__grid-item4{
        grid-column: 4/9;
        grid-row: 7/11;
    }

    .main__grid-item{
        border-radius: 10px;
    }
    .main__container{
        flex-direction: column;
        gap: 30px;
    }

    .main{
        margin-top: 50px;
    }
  }