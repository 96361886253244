.chatbot{
    /* background-color: black; */
    padding-top: 110px;
    padding-bottom: 110px;
}

.chatbot__container{
    max-width: 1280px;
    width: 100%;
    margin:  0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 0px;
    padding-left: 20px;
    padding-right: 20px;
    /* padding-bottom: 50px; */
}


.chatbot__overlay-container{
    width: 608px;
    height: 608px;
    border: 2px solid #ccc;
    border-radius: 20px;
    border-color: #ffffff;
    position: relative;
    align-items: center;


}

.chatbot__overlay-container:hover {
    border-color: #baf32e; /* Меняем цвет границы */
    transition: transform 0.4s, border-color 0.4s; /* Плавный переход */
    cursor: pointer;
}

.chatbot__about{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 15px;    
    align-items: flex-start;
    /*max-width: 600px;*/
    width: 580px;
}

.chatbot__image{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    width: 90%;
    height: 90%;

}

.chatbot__image:hover{
    transform: translate(-50%, -50%) scale(1.03);
    transition: transform 0.4s;
}


.chatbot__image2{
    position: absolute;
    width: 250px;
    height: auto;
    top: 350px;
    left: 20px;
}

.chatbot__title{
    margin: 0;
    font-size: 25px !important;
    text-align: left;
    text-transform: uppercase;
    line-height: 1.5;
}

.chatbot__subtitle{
    font-family: DERZKY;
    font-size: clamp(18px, 2vw, 20px);
    line-height: 1.25;
    margin-left: 0px;
    padding-left: 0px;
    z-index: 1;
    text-align: left;
}

.chatbot__button{
    border: 2px solid #baf32e;
    background-color: transparent;
    border-radius: 30px;
    color: #ffffff;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}

.chatbot__button{
    transition: transform 0.2s linear;
}

.chatbot__button:hover{
    transform: scale(1.07);
}


@media screen and (max-width: 1250px){
    
    .chatbot__container {
        width: 100%;
        gap: 20px;
        padding-left: 0;
        padding-right: 0;
       
    }

    .chatbot__overlay-container{
        width: 350px;
        height: 350px;
    }

    

    .chatbot__image2{
        width: 150px;
        height: auto;
        top: 200px;
        left: 20px;
    }

   
}

@media screen and (max-width: 860px){
    .chatbot__container {
        flex-direction: column;
    }

    .chatbot__about{
        width: 350px;
    }

    .chatbot__about{
        align-items: center;
    }

    .chatbot__title{
        text-align: center;
        font-size: 20px !important;
    }

    .chatbot__subtitle{
        text-align: center;
        font-size: 16px !important;
    }

}